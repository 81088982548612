import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, resetGetUsers } from '../../store/user/getUsers.slice';
import { Table, Input, Pagination, Container, Header, Content, InputGroup, IconButton, Button, Stack, Modal } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useNavigate } from 'react-router-dom';
import { getUserPackages, resetGetUserPackages } from '../../store/package/getUserPackages.slice';

const { Column, HeaderCell, Cell } = Table;

function Users() {
  const dispatch = useDispatch();
  const getUsersState = useSelector((state) => state.getUsers);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const getUserPackagesState = useSelector((state) => state.getUserPackages);

  useEffect(() => {
    fetchUsers();
  }, [page, pageSize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const fetchUsers = () => {
    dispatch(getUsers({ pageSize, pageNumber: page, search: searchTerm }));
  };

  const handleSearch = () => {
    setPage(1);
    fetchUsers();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleViewUserPackage = (userId) => {
    setSelectedUserId(userId);
    setShowPackageModal(true);
    dispatch(getUserPackages({ userId }));
  };

  const handleClosePackageModal = () => {
    setShowPackageModal(false);
    setSelectedUserId(null);
    dispatch(resetGetUserPackages());
  };

  const handleViewUserFeedbacks = (userId) => {
    // Implement view user feedbacks functionality
    console.log('View user feedbacks for user ID:', userId);
  };

  const handleViewDetail = (userId) => {
    navigate(`/user-detail/${userId}`);
  };

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          wordWrap: "break-word"
        }}>
          <h4>Users</h4>
          <InputGroup inside style={{ width: 300 }}>
            <Input 
              placeholder="Search users"
              value={searchTerm}
              onChange={setSearchTerm}
              onKeyPress={handleKeyPress}
            />
            <InputGroup.Button onClick={handleSearch}>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Header>
      <Content>
        <Table
          height={400}
          data={getUsersState.getUsers?.result?.users || []}
          loading={getUsersState.spinner}
          autoHeight
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={150} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Surname</HeaderCell>
            <Cell dataKey="surname" />
          </Column>
        
          <Column width={150} flexGrow={1}>
            <HeaderCell>Phone Number</HeaderCell>
            <Cell dataKey="phoneNumber" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Education Level</HeaderCell>
            <Cell dataKey="levelOfEducation.name" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Target Exam</HeaderCell>
            <Cell dataKey="targetExam.name" />
          </Column>
          <Column width={200} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {rowData => (
                <Stack direction="column" spacing={4} alignItems="stretch">
                  <Button size="xs" onClick={() => handleViewDetail(rowData.id)}>
                    <i className="fa-solid fa-user-gear"></i>&nbsp; Detail
                  </Button>
                  <Button size="xs" onClick={() => handleViewUserPackage(rowData.id)}>
                    <i className="fa-solid fa-box-archive"></i>&nbsp; View Package
                  </Button>
                  <Button size="xs" onClick={() => handleViewUserFeedbacks(rowData.id)}>
                    <i className="fa-solid fa-comments"></i>&nbsp; View Feedbacks
                  </Button>
                </Stack>
              )}
            </Cell>
          </Column>
        </Table>

        <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'end' }}>
          <Pagination
            total={getUsersState.getUsers?.result?.totalCount || 0}
            limit={pageSize}
            activePage={page}
            onChangePage={setPage}
          />
        </div>
      </Content>

      <Modal size="lg" open={showPackageModal} onClose={handleClosePackageModal}>
        <Modal.Header>
          <Modal.Title>User Packages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getUserPackagesState.spinner ? (
            <div>Loading...</div>
          ) : (
            <Table
              autoHeight
              data={getUserPackagesState.getUserPackages?.result?.userPackages || []}
              wordWrap="break-word"
            >
              <Column width={150} flexGrow={1}>
                <HeaderCell>Package Name</HeaderCell>
                <Cell dataKey="package.name" />
              </Column>
              <Column width={100}>
                <HeaderCell>Total Rights</HeaderCell>
                <Cell dataKey="totalRights" />
              </Column>
              <Column width={100}>
                <HeaderCell>Used Rights</HeaderCell>
                <Cell dataKey="usedRights" />
              </Column>
              <Column width={150}>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" />
              </Column>
              <Column width={200}>
                <HeaderCell>Purchase Date</HeaderCell>
                <Cell>
                  {rowData => new Date(rowData.purchaseTime).toLocaleDateString()}
                </Cell>
              </Column>
              <Column width={200}>
                <HeaderCell>Expiration Date</HeaderCell>
                <Cell>
                  {rowData => new Date(rowData.expirationTime).toLocaleDateString()}
                </Cell>
              </Column>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClosePackageModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Users;
