import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Modal, Form, Input, IconButton, Container, Header, Content, Checkbox, CheckboxGroup } from "rsuite";
import { getExams } from "../../store/exam/getExams.slice";
import { resetUpdateExam, updateExam } from "../../store/exam/updateExam.slice";
import { addExam, resetAddExam } from "../../store/exam/addExam.slice";
import { deleteExam, resetDeleteExam } from "../../store/exam/deleteExam.slice";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { yaml } from "@codemirror/lang-yaml";
import PlusIcon from "@rsuite/icons/Plus";
import { getLevelOfEducations } from "../../store/level-of-education/levelOfEducation.slice";
import CopyIcon from '@rsuite/icons/Copy';

const { Column, HeaderCell, Cell } = Table;

function Exams() {
  const getExamsState = useSelector((state) => state.getExams);
  const updateExamState = useSelector((state) => state.updateExam);
  const addExamState = useSelector((state) => state.addExam);
  const deleteExamState = useSelector((state) => state.deleteExam);
  const levelOfEducationState = useSelector((state) => state.levelOfEducation);
  const dispatch = useDispatch();

  const isCreating = addExamState.spinner;
  const isUpdating = updateExamState.spinner;
  const isDeleting = deleteExamState.spinner;
  const isLoading = getExamsState.spinner;


  useEffect(() => {
    dispatch(getExams());
    dispatch(getLevelOfEducations());
  }, [dispatch]);

  let data = [];
  if (getExamsState.done) {
    data = getExamsState?.getExams?.result?.targetExams || [];
  }

  const [open, setOpen] = useState(false);
  const [editingExam, setEditingExam] = useState(null);
  const [tableWidth, setTableWidth] = useState('100%');
  const containerRef = useRef(null);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [newExam, setNewExam] = useState({ 
    name: '', 
    description: '',
    levelOfEducations: []
  });

  const [code, setCode] = useState('');
  useEffect(() => {
    if (addExamState.done) {
      dispatch(getExams());
      dispatch(resetAddExam());
      setOpenAddModal(false);
    }
  }, [addExamState.done, dispatch]);

  useEffect(() => {
    if (updateExamState.done) {
      dispatch(getExams());
      dispatch(resetUpdateExam());
    }
  }, [updateExamState.done, dispatch]);

  useEffect(() => {
    if (deleteExamState.done) {
      dispatch(getExams());
      dispatch(resetDeleteExam());
    }
  }, [deleteExamState.done, dispatch]);

  const handleDelete = (id) => {
    setDeletingId(id);
    dispatch(deleteExam(id));
  };

  const handleEdit = (exam) => {
    setEditingExam({
      ...exam,
      levelOfEducations: exam.levelOfEducations?.map(level => level.id) || []
    });
    setOpen(true);
  };

  const handleClose = () => {
    if (isUpdating) return;
    setOpen(false);
    setEditingExam(null);
  };

  const handleAddModalClose = () => {
    if (isCreating) return;
    setOpenAddModal(false);
    setNewExam({ name: '', description: '', levelOfEducations: [] });
  };

  const handleSave = () => {
    const payload = {
      id: editingExam.id,
      data: {
        name: editingExam.name,
        description: editingExam.description,
        style: editingExam.style,
        levelOfEducations: editingExam.levelOfEducations || []
      }
    };

    dispatch(updateExam(payload));
  };

  const handleAddExam = () => {
    dispatch(addExam({ 
      name: newExam.name, 
      description: newExam.description,
      style: newExam.style,
      levelOfEducations: newExam.levelOfEducations || []
    }));
  };

  const handleSaveCode = () => {
    console.log("Saving code:", code);
    // Burada API çağrısı yapılabilir veya başka bir işlem gerçekleştirilebilir
  };

  const allEducationLevels = levelOfEducationState.list.data?.result?.levelOfEducations || [];

  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    if (deleteExamState.done) {
      setDeletingId(null);
    }
  }, [deleteExamState.done]);

  useEffect(() => {
    if (updateExamState.done) {
      dispatch(getExams());
      dispatch(resetUpdateExam());
      setOpen(false);
      setEditingExam(null);
    }
  }, [updateExamState.done, dispatch]);

  useEffect(() => {
    if (addExamState.done) {
      dispatch(getExams());
      dispatch(resetAddExam());
      setOpenAddModal(false);
      setNewExam({ name: '', description: '', levelOfEducations: [] });
    }
  }, [addExamState.done, dispatch]);

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}>
          <h4>Target Exams</h4>
          <Button
            icon={<PlusIcon />}
            appearance="primary"
            onClick={() => setOpenAddModal(true)}
          >
            Add Exam
          </Button>
        </div>
      </Header>

      <Content>
        <Table
          height={400}
          data={data}
          wordWrap="break-word"
          rowHeight={60}
          loading={isLoading}
        >
          <Column width={350} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell>
              {rowData => (
                <div style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <div style={{ 
                    maxWidth: '280px', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    fontSize: '0.9em',
                    fontFamily: 'monospace'
                  }} 
                  title={rowData.id}
                  >
                    {rowData.id}
                  </div>
                  <button
                    style={{
                      border: 'none',
                      background: 'transparent',
                      cursor: 'pointer',
                      padding: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      opacity: 0.6,
                      transition: 'opacity 0.2s'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(rowData.id);
                    }}
                    title="Copy ID"
                    onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                    onMouseLeave={(e) => e.currentTarget.style.opacity = '0.6'}
                  >
                    <i className="fas fa-copy" style={{ fontSize: '14px' }} />
                  </button>
                </div>
              )}
            </Cell>
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column width={200} flexGrow={1}>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>

          <Column width={150} flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>

          <Column width={150} flexGrow={1}>
            <HeaderCell>Updated At</HeaderCell>
            <Cell dataKey="updatedAt" />
          </Column>

          <Column width={250} flexGrow={1}>
            <HeaderCell>Education Levels</HeaderCell>
            <Cell>
              {rowData => (
                <div>
                  {rowData.levelOfEducations?.map(level => level.name).join(', ') || '-'}
                </div>
              )}
            </Cell>
          </Column>

          

          <Column width={200} flexGrow={1} align="center" fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <>
                  <Button
                    appearance="primary"
                    style={{ marginRight: 8 }}
                    color="red"
                    onClick={() => handleDelete(rowData.id)}
                    loading={isDeleting && deletingId === rowData.id}
                    disabled={isDeleting}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </Button>
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={() => handleEdit(rowData)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </Button>
                </>
              )}
            </Cell>
          </Column>
        </Table>

        <Modal 
          open={open} 
          onClose={handleClose}
          backdrop="static" // Prevent closing by clicking outside
          keyboard={false} // Prevent closing by pressing ESC
        >
          <Modal.Header>
            <Modal.Title>Edit Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editingExam && (
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={editingExam.name}
                    onChange={(value) => setEditingExam({ ...editingExam, name: value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Description</Form.ControlLabel>
                  <Form.Control
                    name="description"
                    value={editingExam.description}
                    onChange={(value) => setEditingExam({ ...editingExam, description: value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Education Levels</Form.ControlLabel>
                  <div style={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    gap: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto'
                  }}>
                    <Form.Control
                      name="levelOfEducations"
                      accepter={CheckboxGroup}
                      inline
                      value={editingExam.levelOfEducations || []}
                      onChange={(value) => {
                        setEditingExam({ 
                          ...editingExam, 
                          levelOfEducations: value
                        });
                      }}
                    >
                      {allEducationLevels.map(level => (
                        <Checkbox key={level.id} value={level.id}>
                          {level.name}
                        </Checkbox>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              onClick={handleSave} 
              appearance="primary"
              loading={isUpdating}
              disabled={isUpdating}
            >
              Save
            </Button>
            <Button 
              onClick={handleClose} 
              appearance="subtle"
              disabled={isUpdating}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal 
          open={openAddModal} 
          onClose={handleAddModalClose}
          backdrop="static" // Prevent closing by clicking outside
          keyboard={false} // Prevent closing by pressing ESC
        >
          <Modal.Header>
            <Modal.Title>Add New Exam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  name="name"
                  value={newExam.name}
                  onChange={(value) => setNewExam({ ...newExam, name: value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Description</Form.ControlLabel>
                <Form.Control
                  name="description"
                  value={newExam.description}
                  onChange={(value) => setNewExam({ ...newExam, description: value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Education Levels</Form.ControlLabel>
                <div style={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: '10px',
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}>
                  <Form.Control
                    name="levelOfEducations"
                    accepter={CheckboxGroup}
                    inline
                    value={newExam.levelOfEducations || []}
                    onChange={(value) => {
                      setNewExam({ 
                        ...newExam, 
                        levelOfEducations: value
                      });
                    }}
                  >
                    {allEducationLevels.map(level => (
                      <Checkbox key={level.id} value={level.id}>
                        {level.name}
                      </Checkbox>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              onClick={handleAddExam} 
              appearance="primary"
              loading={isCreating}
              disabled={isCreating}
            >
              Add
            </Button>
            <Button 
              onClick={handleAddModalClose} 
              appearance="subtle"
              disabled={isCreating}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Content>
    </Container>
  );
}

export default Exams;
