import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getFeedbacksByUserId";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getFeedbacksByUserId: null,
  };
}

export const getFeedbacksByUserId = createAsyncThunk(
  "getFeedbacksByUserId",
  async ({ userId }, rejectWithValue) => {
    try {
      const response = await api.get(`/super/api/feedback/get/by-user/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const getFeedbacksByUserIdSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetgetFeedbacksByUserId: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getFeedbacksByUserId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacksByUserId.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getFeedbacksByUserId.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getFeedbacksByUserId = action.payload;
      })
      .addCase(getFeedbacksByUserId.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetgetFeedbacksByUserId } = getFeedbacksByUserIdSlice.actions;
export const getFeedbacksByUserIdReducer = getFeedbacksByUserIdSlice.reducer;
